import ContentBlock from "../../../components/UI/ContentBlock"
import ContentCard from "../../../components/UI/ContentCard"
import styled from "styled-components"
import {useEffect} from "react"
import {Button} from "antd"
import {useNavigate} from "react-router-dom"

const FinesScreen = () => {

	const navigate = useNavigate()

	useEffect(() => {
		document.title = 'АТС24 — Штрафы ГИБДД'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Штрафы ГИБДД" large>
					<p>
						Автоматическая проверка штрафов с привязкой к водителю.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40} bottom={0}>
				<Section>
					<img width="100%" src="/assets/img/jpg/services/fines/main.jpg"/>
				</Section>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Как это работает?">
					<p>
						Просто добавьте номер СТС к карточке автомобиля, и мы автоматически начнём отслеживать новые
						штрафы.
					</p>
				</ContentCard>

				<ContentCard title="Кому штраф?" small
							 media={{
								 url: "/assets/img/jpg/services/fines/owner.jpg",
								 width: 780
							 }}
				>
					<p>
						При запуске маршрутного листа водитель назначается на указанное транспортное средство, и мы
						сопоставляем время штрафа с периодом аренды этого автомобиля.
					</p>
				</ContentCard>

				<ContentCard
					reverse title="Сообщим водителю" small
					media={{
						url: "/assets/img/jpg/services/fines/notification.jpg",
						width: 780
					}}
				>
					<p>
						Важно сразу уведомить водителя о получении штрафа на рабочий автомобиль, ведь камеры появляются
						в новых местах с высокой регулярностью.
					</p>
				</ContentCard>

				<ContentCard
					title="Спишем с зарплаты" small
					media={{
						url: "/assets/img/jpg/services/fines/motivation.jpg",
						width: 780
					}}
				>
					<p>
						Подключите систему мотивации, и мы сможем автоматически списывать штрафы с зарплаты водителей и
						понижать их рейтинг.
					</p>
					<p>
						<Button color="default" variant="solid" size="large"
								onClick={() => navigate('/services/motivation')}>
							Подробней о мотивации
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>

		</>
	)
}

export default FinesScreen

const Section = styled.div`

`
