import ContentBlock from "../../../components/UI/ContentBlock"
import ContentCard from "../../../components/UI/ContentCard"
import styled from "styled-components"
import {useEffect} from "react"
import {useNavigate} from "react-router-dom"
import FAQ from "../../../components/FAQ"
import {Button} from "antd"

const TemperatureScreen = () => {

	const navigate = useNavigate()

	useEffect(() => {
		document.title = 'АТС24 — Телеграм бот'
	}, [])

	return (
		<>
			<ContentBlock top={60} bottom={0}>
				<ContentCard title="Контроль температуры" large>
					<p>
						Мониторинг температурного режима с уведомлением о превышении порогов
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={40} bottom={0}>
				<Section>
					<img width="100%" src="/assets/img/jpg/services/temperature/main.jpg"/>
				</Section>
			</ContentBlock>

			<ContentBlock bottom={0}>
				<ContentCard title="Как это работает?">
					<p>
						Каждому маршруту можно выбрать температурный режим, при котором должна осуществляться
						перевозка.
					</p>
					<p>
						Во время движения мы будем внимательно следить за температурой и уведомлять, если режим будет
						нарушен.
					</p>
				</ContentCard>
			</ContentBlock>
			<ContentBlock top={0}>
				<ContentCard title="Датчик" small
							 media={{
								 url: "/assets/img/jpg/services/temperature/sensor.jpg",
								 width: 780
							 }}
				>
					<p>
						Герметичное беспроводное устройтво, которое подключается к основному блоку GPS.
					</p>
					<p>
						Bluetooth Low Energy минимизирует потребление энергии, позволяя датчику работать до двух лет на
						одной батарее.
					</p>
				</ContentCard>

				<ContentCard title="Уведомления" small reverse
							 media={{
								 url: "/assets/img/jpg/services/temperature/notifications.jpg",
								 width: 780
							 }}
				>
					<p>
						Если температура выходит за установленные пределы, наш Telegram бот немедленно уведомит
						логистов.
					</p>
					<p>
						Система мотивации позволит автоматически фиксировать нарушения и удерживать баллы или средства
						со счёта водителя.
					</p>
					<p>
						<Button color="default" variant="solid" size="large"
								onClick={() => navigate('/services/motivation')}>
							Подробней о системе мотивации
						</Button>
					</p>
				</ContentCard>

				<ContentCard title="На карте" small
							 media={{
								 url: "/assets/img/jpg/services/temperature/map.jpg",
								 width: 780
							 }}
				>
					<p>
						В зависимости от заданного режима и температуры в кузове, трек водителя будет окрашен в разные
						цвета, чтобы вы могли быстро заметить отклонения.
					</p>
				</ContentCard>

				<ContentCard title="Готовность к погрузке" small reverse
							 media={{
								 url: "/assets/img/jpg/services/temperature/schedule.jpg",
								 width: 780
							 }}
				>
					<p>
						На нашем TV-терминале отображаются маршруты на погрузку и готовность температуры в кузове.
					</p>
				</ContentCard>
				{/*<ContentCard title="Экспорт отчёта" small
							 media={{
								 url: "/assets/img/jpg/services/temperature/schedule.jpg",
								 width: 780
							 }}
				>
					<p>
						В карточке маршрута можно скачать отчёт о перемещении и график температур.
					</p>
					<p>
						Подключите сервис «кабинет клиента», чтобы заказчик мог следить за перевозкой в режиме
						онлайн.
					</p>
					<p>
						<Button color="default" variant="solid" size="large"
								onClick={() => navigate('/services/observers')}>
							Подробней о кабинете клиента
						</Button>
					</p>
				</ContentCard>*/}
			</ContentBlock>

			<ContentBlock background="#F5F5F5">
				<FAQ questions={[
					{
						showArrow: false,
						label: 'Сколько датчиков в один кузов?',
						children: 'Можно подключить до шести датчиков в одно транспортное средство.'
					},
					{
						showArrow: false,
						label: 'Можно задавать свои режимы?',
						children: 'Да, вы можете задать неограниченное количество режимов и использовать их для разных маршрутов.'
					},
					{
						showArrow: false,
						label: 'Какая погрешность?',
						children: 'Погрешность датчиков составляет 0.1С°.'
					},
					{
						showArrow: false,
						label: 'Сколько это стоит?',
						children: 'Актуальную стоимость оборудования и монтажа можно узнать в разделе с тарифами.'
					}
				]}/>
			</ContentBlock>

		</>
	)
}

export default TemperatureScreen

const Section = styled.div`

`
