import ContentBlock from "../../components/UI/ContentBlock"
import ContentCard from "../../components/UI/ContentCard"
import styled from "styled-components"
import ContentHeader from "../../components/UI/ContentHeader"
import {useEffect} from "react"
import Map from "./component/Map"
import CustomerReview from "../../components/CustomerReview"
import {Button} from "antd"
import {useNavigate} from "react-router-dom"
import FAQ from "../../components/FAQ"

const MonitoringScreen = () => {
	const navigate = useNavigate()

	useEffect(() => {
		document.title = 'АТС24 — Мониторинг'
	}, [])

	return (
		<>
			<ContentBlock center top={60} bottom={0}>
				<div align="center">
					<ContentHeader large>
						Мониторинг
					</ContentHeader>
					<p style={{fontSize: 24}}>
						Местоположение, статусы точек, скоростной режим, контроль топлива и температуры.
					</p>
				</div>
			</ContentBlock>
			<ContentBlock top={40} style={{height: 550}}>
				<Map/>
			</ContentBlock>

			<ContentBlock>
				<ContentCard title="Наш подход" small>
					<p>
						Мы не просто отслеживаем местоположение транспорта, а автоматически накладывем данные о
						перемещении на маршрутные листы и заказы. Такой метод извлекает максимум пользы от трекинга.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock background="#F1F9D8">
				<ContentCard title="Терминал" small>
					<p>
						Основной инструмент логиста, в котором он может отслеживать процесс доставки, быстро искать
						информацию, видеть важные события и статистику.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock top={0} background="#F1F9D8">
				<ContentCard
					reverse title="Карта" mini
					media={{
						url: "/assets/img/jpg/monitoring/map.jpg",
						width: 780
					}}
				>
					<p>
						На карте отображаются все точки доставки за выбранный день, раскрашенные в зависимости от их
						статусов.
					</p>
					<p>
						Достаточно навести на точку, и во всплывающем окне откроется вся информация о ней.
					</p>
				</ContentCard>

				<ContentCard
					title="Маршруты" mini
					media={{
						url: "/assets/img/jpg/monitoring/routes.jpg",
						width: 780
					}}
				>
					<p>
						Выберите нужный маршрут из списка, и терминал отобразит его точки, время посещений и фактический
						маршрут движения.
					</p>
				</ContentCard>

				<ContentCard
					title="Быстрый поиск" mini reverse
					media={{
						url: "/assets/img/jpg/monitoring/search.jpg",
						width: 780
					}}
				>
					<p>
						Мгновенный поиск по более чем 30 полям: водителям, автомобилям, маршрутам, адресам, клиентам,
						накладным.
					</p>
					<p>
						На карте останутся только точки из найденных маршрутов.
					</p>
				</ContentCard>

				<ContentCard
					title="Фильтры" mini
					media={{
						url: "/assets/img/jpg/monitoring/filters.jpg",
						width: 780
					}}
				>
					<p>
						Фильтры позволяют быстро выделить на карте необходимые маршруты, точки или транспорт, а система
						меток — объединить объекты в группы.
					</p>
				</ContentCard>

				<ContentCard
					title="Оперативный отчёт" mini reverse radius={0}
					media={{
						url: "/assets/img/jpg/monitoring/report.jpg",
						width: 780
					}}
				>
					<p>
						В один клик логист может выгрузить все данные о доставках за выбранный день в файл Excel. Также
						доступны индивидуальные отчёты.
					</p>
					<p>
						<Button color="default" variant="solid" size="large"
								onClick={() => navigate('/services/reports')}>
							Подробней об отчётах
						</Button>
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock>
				<CustomerReview
					autor="Игорь Скатов, руководитель логистики"
					description="Удобный интерфейс, круглосуточная поддержка и крутое мобильное приложение"
					media={{
						url: "/assets/img/png/monitoring/logo-grain.png",
						width: 300
					}}
				/>
			</ContentBlock>

			<ContentBlock background="#F5F5F5">
				<ContentCard title="Автоматические статусы" small
							 media={{
								 url: "/assets/img/jpg/monitoring/automount.jpg",
								 width: 780
							 }}
				>
					<p>
						Включите опцию, чтобы система автоматически фиксировала посещение точек на основе данных
						мобильного приложения или датчиков в автомобиле.
					</p>
				</ContentCard>
				<ContentCard title="Журнал событий" small reverse
							 media={{
								 url: "/assets/img/jpg/monitoring/logs.jpg",
								 width: 780
							 }}
				>
					<p>
						Мы собираем все действия пользователей и храним их бессрочно. Для удобства все данные
						сгруппированы по маршрутным листам и точкам.
					</p>
				</ContentCard>
				<ContentCard title="Поддержка филиалов" small
							 media={{
								 url: "/assets/img/jpg/monitoring/offices.jpg",
								 width: 780
							 }}
				>
					<p>
						Перемещайтесь между вашими филиалами одним кликом мыши.
					</p>
					<p>
						Каждый филиал имеет индивидуальные настройки, логистов, водителей и парк транспортных средств.
					</p>
				</ContentCard>
			</ContentBlock>

			<ContentBlock background="#dbf7ff">
				<ContentCard title="Внешние устройства и датчики">
					<p>
						Ниже представлены основные внешние устройства, с которыми работает АТС24.
					</p>
				</ContentCard>

				<TilesWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/monitoring/sensor-gps.jpg"/>
						<ContentHeader mini>GPS трекер</ContentHeader>
						<p>
							Обладает высокой точностью местоположения, частотой обновления, автоматическим проставлением
							выполнения заданий и многими другими функциями.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/monitoring/sensor-fuel.jpg"/>
						<ContentHeader mini>Уровень топлива</ContentHeader>
						<p>
							На некоторых автомобилях можно получать данные об уровне топлива через CAN-шину, но при
							необходимости можно установить стационарный датчик.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/monitoring/sensor-temp.jpg"/>
						<ContentHeader mini>Температурный режим</ContentHeader>
						<p>
							Контролируйте температурный режим в рефрижераторах во время доставки <a
							onClick={() => navigate('/services/temperature')}>охлаждённых и замороженных продуктов</a>.
						</p>
					</TileWrapper>
					<TileWrapper>
						<img width={540}
							 src="/assets/img/jpg/monitoring/sensor-gate.jpg"/>
						<ContentHeader mini>Открытие ворот</ContentHeader>
						<p>
							Наш <a onClick={() => navigate('/services/telegram')}>Telegram-бот</a> оповестит о любом
							открытии ворот за пределами зон загрузки и выгрузки автомобиля.
						</p>
					</TileWrapper>
				</TilesWrapper>
			</ContentBlock>
			{/*<ContentBlock background="#F5F5F5">
				<FAQ questions={[
					{
						showArrow: false,
						label: 'Какие бывают категории?',
						children: 'Список категорий, их названия и возможные коэффициенты вы назначаете сами.'
					},
					{
						showArrow: false,
						label: 'Можно ли исполь?',
						children: 'Список категорий, их названия и возможные коэффициенты вы назначаете сами.'
					},
					{
						showArrow: false,
						label: 'Правда, что события могут быть абсолютно любыми?',
						children: 'Да, события и условия их возникновения вы можете придумывать сами. Мы лишь ограничиваемся набором данных, которые у нас есть. Также существует возможность обращаться к любым внешним ресурсам для более тонкой настройки.'
					},
					{
						showArrow: false,
						label: 'Могут ли баллы сниматься временно?',
						children: 'Да, баллы могут сниматься на время. К примеру: водитель опоздал на погрузку и мы снего сняли 20 баллов на одну неделю, в течении которой он получает по пониженному коэффициенту. Через неделю баллы восстанавливаются и он возвращается на привычную категорию автоматически.'
					},
					{
						showArrow: false,
						label: 'Можно ли считать категории в конце месяца?',
						children: 'Да, мы можем в течении месяца начислять водителю по базовому тарифу, а в конце месяца умножить все начисления на коэффициент итоговой категории.'
					},
					{
						showArrow: false,
						label: 'Разные маршруты — разные тарифы?',
						children: 'Да, мы можем с вами определить разные категории маршрутов или ТС, и считать их по отдельным формулам.'
					},
					{
						showArrow: false,
						label: 'Сколько стоит эта система?',
						children: 'В среднем 300-600 рублей в месяц за водителя. Финальную стоимость настройки и абонентской платы мы сможем определить после детального обсуждения именно вашей системы мотивации.'
					}
				]}/>
			</ContentBlock>*/}
		</>
	)
}

export default MonitoringScreen
const TilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    width: 1160px;
`
const TileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 540px;
    overflow: hidden;

    img {
        margin-bottom: 32px;
        border-radius: 35px;
    }
`
